import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1aaa7bdb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "welcome" }
const _hoisted_2 = { class: "welcome-container" }
const _hoisted_3 = { class: "tagline" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "welcome-image" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "welcome-message" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "help-link" }
const _hoisted_10 = { class: "message" }
const _hoisted_11 = {
  href: "https://help.digitalsign.jp/hc/ja/articles/4406942274329",
  target: "_blank"
}
const _hoisted_12 = { class: "message" }
const _hoisted_13 = {
  href: "https://help.digitalsign.jp/hc/ja/articles/5054635472153",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlatButton = _resolveComponent("FlatButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("onboarding.welcomeTaglineText")), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("onboarding.welcomeTitleText")), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("img", {
          src: require('images/welcome.svg'),
          alt: "Welcome image"
        }, null, 8, _hoisted_6)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("p", {
          innerHTML: _ctx.$t('onboarding.welcomeInfoLine'),
          class: "message"
        }, null, 8, _hoisted_8)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("p", _hoisted_10, [
          _createTextVNode(_toDisplayString(_ctx.$t('onboarding.helpLinkOrganizationFirst')), 1),
          _createElementVNode("a", _hoisted_11, _toDisplayString(_ctx.$t('onboarding.helpLinkHere')), 1),
          _createTextVNode(_toDisplayString(_ctx.$t('onboarding.helpLinkLast')), 1)
        ]),
        _createElementVNode("p", _hoisted_12, [
          _createTextVNode(" （" + _toDisplayString(_ctx.$t('onboarding.helpLinkPersonalFirst')), 1),
          _createElementVNode("a", _hoisted_13, _toDisplayString(_ctx.$t('onboarding.helpLinkHere')), 1),
          _createTextVNode(_toDisplayString(_ctx.$t('onboarding.helpLinkLast')) + "） ", 1)
        ])
      ]),
      _createVNode(_component_FlatButton, {
        onOnClick: _ctx.goNext,
        text: _ctx.$t('onboarding.welcomeButtonText'),
        class: "welcome-button w-full"
      }, null, 8, ["onOnClick", "text"])
    ])
  ]))
}