
// @ is an alias to /src
import FlatButton from "@/components/atomics/FlatButton.vue";
import { defineComponent, onMounted, ref } from "vue";
import { storage, key } from "@/lib/storage";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "Welcome",
  components: {
    FlatButton,
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();

    onMounted(() => {
      const localStorage = storage.getLocalStorage();
      if (route.query.sessionId)
        localStorage.add(key.sessionId, route.query.sessionId);
    });

    const goNext = () => {
      router.push({
        name: "Terms",
        query: {
          isCompanyCreating: route.query.isCompanyCreating,
          partner: route.query.partner
        },
      });
    };

    return {
      goNext,
    };
  },
});
