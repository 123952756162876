
import { defineComponent, reactive, toRefs } from "vue";

export default defineComponent({
  name: "FlatButton",
  props: {
    text: { type: String, required: true },
    enabled: { type: Boolean, default: true },
    icon: { type: String },
    rightIcon: { type: String },
    onClick: { type: Function },
  },
  setup(props: any, ctx: any) {
    const state = reactive({
      isLoading: false,
    });

    const click = async () => {
      if (props.onClick) {
        state.isLoading = true;
        ctx.emit("onLoading", true);
        await props.onClick();
        ctx.emit("onLoading", false);

        state.isLoading = false;
      } else {
        ctx.emit("onClick", null);
      }
    };

    return {
      ...toRefs(state),
      click,
    };
  },
});
